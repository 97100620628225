import { DatasourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { ServiceType } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/serviceType.interface';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { Vocabulary } from '../../../features/dashboard/contributors-dashboard/interfaces/vocabulary.interface';
import { Offer } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/offer.interface';

export interface ContributorsDashboardFormState {
  contributorMode: boolean;
  contributor: ProviderBundle | null;
  contributors: { total: number; results: ProviderBundle[]; from: number };
  service: ServiceBundle | null;
  services: ServiceBundle[];
  datasource: DatasourceBundle | null;
  serviceDatasource: ServiceBundle | null;
  datasources: DatasourceBundle[];
  trainingResource: TrainingResourceBundle | null;
  trainingResources: TrainingResourceBundle[];
  interoperabilityRecord: InteroperabilityRecordBundle | null;
  interoperabilityRecords: InteroperabilityRecordBundle[];
  vocabularies: { [key: string]: Vocabulary[] };
  serviceType: ServiceType | null;
  serviceOffers: Offer[];
  loading: boolean;
  error: string;
}

export const initialState: ContributorsDashboardFormState = {
  contributorMode: false,
  contributor: null,
  contributors: { total: 0, results: [], from: 0 },
  service: null,
  services: [],
  datasource: null,
  serviceDatasource: null,
  datasources: [],
  trainingResource: null,
  trainingResources: [],
  interoperabilityRecord: null,
  interoperabilityRecords: [],
  vocabularies: {},
  serviceType: null,
  serviceOffers: [],
  loading: false,
  error: '',
};

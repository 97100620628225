import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/misc/auth.service';
import { environment } from '../../../environments/environment';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);

  if (authService.hasValidAccessToken()) return true;

  localStorage.setItem('auth-redirect-path', state.url);

  authService.initLoginFlow();

  if (environment.skipAuthInitialization) console.error('Auth initialization is skipped!');

  return false;
};

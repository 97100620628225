import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { UserProfile } from '../../interfaces/auth';

export const AuthActions = createActionGroup({
    source: 'Auth',
    events: {
        'Try Login': emptyProps(),
        'Try Login Success': props<{ userProfile: UserProfile | null }>(),
        'Try Login Failure': props<{ error: string }>(),
    },
});

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FetchedItem } from '../../interfaces/generic/fetched-item';
import { UserProfile } from '../../interfaces/auth';

export const selectAuth = createFeatureSelector<FetchedItem<UserProfile> & { isComplete: boolean }>('auth');

export const selectIsAuthComplete = createSelector(selectAuth, x => x.isComplete);

export const selectProfile = createSelector(selectAuth, x => x.data);

export const selectIsUserAuthenticated = createSelector(selectAuth, x => x.isComplete && !!x.data);

import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { authCodeFlowConfig, callbackURLPath } from '../../../auth.config';
import { UserProfile } from '../../interfaces/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private oauthService: OAuthService, private router: Router) { }

  async initAuthService() {
    this.oauthService.configure(authCodeFlowConfig);
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();

    let profile: UserProfile | null = null;
    try {
      profile = (await this.oauthService.loadUserProfile() as { info: UserProfile }).info as UserProfile;
    } catch (err) {}

    return profile;
  }

  hasValidAccessToken() {
    return this.oauthService.hasValidAccessToken();
  }

  getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  async getUserProfile() {
    return this.oauthService.loadUserProfile();
  }

  initLoginFlow() {
    this.oauthService.initCodeFlow();
  }

  navigateAfterLogin() {
    if (this.router.url.split('?')[0] !== callbackURLPath) return;
    const authRedirectPath = localStorage.getItem('auth-redirect-path');

    if (!authRedirectPath) return;

    if (!this.hasValidAccessToken()) this.router.navigateByUrl('');

    localStorage.removeItem('auth-redirect-path');
    this.router.navigateByUrl(authRedirectPath);
  }
}
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const callbackURLPath = '/logged-in';

export const authCodeFlowConfig: AuthConfig = {
    issuer: environment.authIssuer,
    redirectUri: window.location.origin + callbackURLPath,
    clientId: environment.authClientID,
    responseType: 'code',
    scope: 'openid profile email offline_access entitlements',
    showDebugInformation: false,
    customQueryParams: {
        'prompt': 'consent',
    },
};

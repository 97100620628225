import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import * as ContributorsDashboardSelectors from '../state/contributors-dashboard/contributors-dashboard.selectors';

export const contributorGuard: CanActivateFn = async (route, state) => {
  const store = inject(Store);
  const router = inject(Router);

  const value = await firstValueFrom(store.select(ContributorsDashboardSelectors.selectContributor));

  if (!value) router.navigate(['/dashboard/contributors-dashboard/']);

  return value ? true : false;
};

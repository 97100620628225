import { environment } from '../../../../../../environments/environment';

const baseUrl = `${environment.apiUrl}/action`;
const userOffersBaseUrl = `${environment.apiUrl}/oms/action`;
const offersBaseUrl = `${environment.apiUrl}/oms/api/v1/offers `;

export const contributorsDashboardEndpoints = {
  vocabulary: {
    baseUrl: `${baseUrl}/vocabulary`,
    endpoints: {
      getByType: '/getByType',
    },
  },
  providers: {
    baseUrl: `${baseUrl}/providers`,
    endpoints: {
      getAll: '/getAll',
      update: '/update',
      validate: '/validate',
    },
  },
  services: {
    baseUrl: `${baseUrl}/services`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  datasources: {
    baseUrl: `${baseUrl}/datasources`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  trainingResources: {
    baseUrl: `${baseUrl}/training-resources`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  interoperabilityRecords: {
    baseUrl: `${baseUrl}/interoperability-records`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  userOffers: {
    baseUrl: `${userOffersBaseUrl}/user/offers`,
    endpoints: {
      getParameters: '/parameter',
      getOffers: '/resource',
    },
  },
  offers: {
    baseUrl: `${offersBaseUrl}`,
    endpoints: {},
  },
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, catchError, map, of, from } from 'rxjs';
import { AuthService } from '../../services/misc/auth.service';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
    auth$ = createEffect(() => 
        this.actions$.pipe(
            ofType('[Auth] Try Login'),
            exhaustMap(() => from(this.authService.initAuthService()).pipe(
                map((x) => (AuthActions.tryLoginSuccess({ userProfile: x }))),
                catchError(err => of(AuthActions.tryLoginFailure({ error: err }))),
            )),
        ),
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
    ) {}
}

<mat-stepper orientation="vertical" #stepper>
  @for (step of field.fieldGroup; track $index) {
  <mat-step>
    <ng-template matStepLabel>{{ step.props?.label }}</ng-template>
    <div class="mt-4">
      <formly-field [field]="step"></formly-field>
      @if(!$first) {
      <button mat-button matStepperPrevious>Previous</button>
      } @if(!$last){
      <button mat-button matStepperNext>Next</button>
      }
    </div>
  </mat-step>
  }
</mat-stepper>
<div class="flex flex-row justify-between gap-4 p-4">
  <div class="flex flex-row gap-4">
    <button mat-button>Save</button>
    <button mat-button>Cancel</button>
  </div>
  <button mat-button>Confirm</button>
</div>

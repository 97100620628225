import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

import { LandingPageComponent } from './features/landing-page/landing-page.component';
import { contributorGuard } from './core/guards/contributor.guard';

export const routes: Routes = [
  { path: '', component: LandingPageComponent },
  {
    path: 'logged-in',
    loadComponent: () => import('./features/logged-in/logged-in.component').then((m) => m.LoggedInComponent),
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./features/dashboard/dashboard.component').then((m) => m.DashboardComponent),
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadComponent: () =>
          import('./features/dashboard/home/home.component').then((m) => m.HomeComponent),
        data: { name: 'Home' },
      },
      {
        path: 'workflows',
        loadComponent: () =>
          import('./features/dashboard/workflows/workflows.component').then((m) => m.WorkflowsComponent),
        data: { name: 'Workflows' },
      },
      {
        path: 'orders',
        loadComponent: () => import('./features/dashboard/orders/orders.component').then((m) => m.OrdersComponent),
        data: { name: 'Orders' },
      },
      {
        path: 'requests',
        loadComponent: () => import('./features/dashboard/requests/requests.component').then((m) => m.RequestsComponent),
        data: { name: 'Requests' },
      },
      {
        path: 'curation-hub',
        loadComponent: () =>
          import('./features/dashboard/curation-hub/search-resources/search-resources.component').then(
            (m) => m.SearchResourcesComponent
          ),
        data: { name: 'Curation hub' },
      },
      {
        path: 'user-management',
        loadComponent: () =>
          import('./features/dashboard/user-management/user-management.component').then(
            (m) => m.UserManagementComponent
          ),
        data: { name: 'User Management' },
      },
      {
        path: 'project-management',
        loadComponent: () =>
          import('./features/dashboard/project-management/project-management.component').then(
            (m) => m.ProjectManagementComponent
          ),
        data: { name: 'Project Management' },
      },
      {
        path: 'curation-hub/:resourceType/:id',
        loadComponent: () =>
          import('./features/dashboard/curation-hub/single-resource/single-resource.component').then(
            (m) => m.SingleResourceComponent
          ),
        data: { name: 'Curation hub' },
      },
      {
        path: 'contributors-dashboard',
        loadComponent: () =>
          import('./features/dashboard/contributors-dashboard/contributors-dashboard.component').then(
            (m) => m.ContributorsDashboardComponent
          ),
        children: [
          {
            path: '',
            redirectTo: 'contributors',
            pathMatch: 'full',
          },
          {
            path: 'contributors',
            loadComponent: () =>
              import(
                './features/dashboard/contributors-dashboard/contributors/search-contributors/search-contributors.component'
              ).then((m) => m.SearchContributorsComponent),
          },
          {
            path: ':contributorId',
            canActivate: [contributorGuard],
            loadChildren: () =>
              import(
                './features/dashboard/contributors-dashboard/contributor-dashboard/contributor-dashboard.routes'
              ).then((m) => m.routes),
          },
        ],
        data: { name: 'Contributors dashboard' },
      },
      {
        path: 'tools-market',
        loadComponent: () =>
          import('./features/dashboard/tools-market/tools-market.component').then(
            (m) => m.ToolsMarketComponent
          ),
        data: { name: 'Tools Hub' },
      },
      {
        path: 'tools-market/tool-details/:id',
        loadComponent: () =>
          import('./features/dashboard/tools-market/tool-details/tool-details.component').then(
            (m) => m.ToolDetailsComponent
          ),
        data: { name: 'Tool Details' },
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('./features/page404/page404.component').then((m) => m.Page404Component),
  },
];

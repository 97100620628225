import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [MatStepperModule, MatButtonModule, FormlyModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent extends FieldType {}
